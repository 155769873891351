
import React, {useState} from "react"

import WebappWidgetCard from "../../webapps/widget/card";
import WebappWidgetTable from "../../webapps/widget/table";

import FeedbackListComponent from "./customerinfo/feedbacklist";
import OrderhistoryListComponent from "./customerinfo/orderhistorylist";

import * as tablescroll from "../../../styles/module/tablescroll.module.css"
import * as styles from "../../webapps/style.module.css"

//import formatTools from "../../../../lib/formatTools"
//import webappsAPI from "../../../../lib/requestWebapps"


const codetypelist = [
	{"value":0,"display":"Unknown/None"},
	{"value":1,"display":"Password"},
	{"value":2,"display":"Guest"},

];

const customerFields = [
	{"label":"ID Num", "dbfield": "onsperson_idnum", "type": "text", "filtertype": "textbox"},
	{"label":"Last Name", "dbfield": "onsperson_lastname", "type": "text", "filtertype": "textbox"},
	{"label":"First Name", "dbfield": "onsperson_firstname", "type": "text", "filtertype": "textbox"},
	{"label":"Login Type", "dbfield": "onsperson_codetype", "type": "combo", "filtertype": "textbox", "options":codetypelist},
];


const addressFields = [
	{"label":"Address", "dbfield": "onsaddress_name", "type": "text", "filtertype": "textbox"},
	{"label":"Landmark", "dbfield": "onsaddress_landmark", "type": "text", "filtertype": "textbox"},
	{"label":"Notes", "dbfield": "onsaddress_restrictions", "type": "text", "filtertype": "textbox"},
	{"label":"Primary", "dbfield": "onspersonaddress_primary", "type": "checkbox", "filtertype": "checkbox"},
];

const addressMobileTitle = ["onsaddress_name"];

const addressInitState = {
	"orderbyfield": "onspersonaddress_primary",
	"orderbymode": "desc"
};

const orgFields = [
	{"label":"Organization", "dbfield": "onsorganization_shortname", "type": "text", "filtertype": "textbox"},
	{"label":"Organization Type", "dbfield": "onsorganization_notes", "type": "text", "filtertype": "textbox"},
	{"label":"Role/Position", "dbfield": "onspersonorganization_role", "type": "text", "filtertype": "textbox"},
	{"label":"Start", "dbfield": "onspersonorganization_start", "type": "date", "filtertype": "date"},
	{"label":"End", "dbfield": "onspersonorganization_end", "type": "date", "filtertype": "date"},
];

const orgMobileTitle = ["onsorganization_shortname"];


const orgInitState = {
	"orderbyfield": "onspersonorganization_start",
	"orderbymode": "desc"
};

const contactFields = [
	{"label":"Contact", "dbfield": "onscontact_info", "type": "text", "filtertype": "textbox"},
	{"label":"Type", "dbfield": "onscontacttype_name", "type": "text", "filtertype": "textbox"},
	{"label":"Notes", "dbfield": "onspersoncontact_notes", "type": "text", "filtertype": "textbox"},
	{"label":"Primary", "dbfield": "onspersoncontact_primary", "type": "checkbox", "filtertype": "checkbox"}
];

const contactMobileTitle = ["onscontact_info"];

const contactInitState = {
	"orderbyfield": "onscontacttype_name",
	"orderbymode": "desc"
};


const seminarhistoryFields = [
	{"label":"Date", "dbfield": "nnseminar_datetimestart", "type": "datetime", "filtertype": "none"},
	{"label":"Name", "dbfield": "nnseminar_name", "type": "text", "filtertype": "textbox"},
	{"label":"Image", "dbfield": "nnseminar_bannermobile", "type": "image", "filtertype": "none"},
	{"label":"Payment", "dbfield": "nnseminaruser_paidamount", "type": "currency", "filtertype": "currency"},
];

const seminarhistoryMobileTitle = ["nnseminar_name"];

const seminarhistoryFilterFields = [
	{"label":"Date", "dbfield": "nnseminar_datetimestart", "filtertype": "date"},
	{"label":"Name", "dbfield": "nnseminar_name", "filtertype": "textbox"},
];

const seminarhistoryInitstate = {
	"filter":[
	],
	"orderbyfield": "nnseminar_datetimestart",
	"orderbymode": "desc"
};

const franchisehistoryFields = [
	{"label":"Registered", "dbfield": "nnfranchiseuser_dateregistered", "type": "datetime", "filtertype": "none"},
	{"label":"Franchise", "dbfield": "nnfranchise_name", "type": "text", "filtertype": "none"},
	{"label":"Package", "dbfield": "nnfranchisepackagecode_name", "type": "text", "filtertype": "none"},
	{"label":"Last Updated", "dbfield": "nnfranchiseuser_dateupdated", "type": "datetime", "filtertype": "none"},
	{"label":"Status", "dbfield": "nnfranchiseuserstatus_name", "type": "text", "filtertype": "none"},
	{"label":"Details", "dbfield": "nnfranchiseuser_resultsummary", "type": "text", "filtertype": "textbox"},
];

const franchisehistoryMobileTitle = ["nnfranchise_name", "nnfranchisepackagecode_name"];

const franchisehistoryFilterFields = [
	{"label":"Franchise", "dbfield": "nnfranchise_name", "filtertype": "textbox"},
	{"label":"Status", "dbfield": "nnfranchiseuserstatus_name", "filtertype": "textbox"},
];

const franchisehistoryInitstate = {
	"filter":[
	],
	"orderbyfield": "nnfranchiseuser_dateregistered",
	"orderbymode": "desc"
};


const CustomerProfileComponent = ({location, customerid, token, handleClose}) => {
	const [customername, setCustomername] = useState("Back");

	//const defaultstartdate = formatTools.getDateStr(8-24*180); // ~6months ago

	function setCustomerInfo(newdata)
	{
		if (newdata.hasOwnProperty("onsperson_lastname") && newdata.hasOwnProperty("onsperson_firstname")) {
			var tmpname = "";
			if (newdata.onsperson_lastname !== null) {
				tmpname = newdata.onsperson_lastname;
			}
			if (newdata.onsperson_firstname !== null) {
				if (newdata.onsperson_firstname.length > 0) {
					if (tmpname.length > 0)
						tmpname = tmpname+", ";
					tmpname = tmpname+newdata.onsperson_firstname;
				}
			}
			if (tmpname.length >0) {
				setCustomername(tmpname);
			}
		}
	}

	const customerQueryparams = {
		//tablelist: [],
		joinlist: [],
		condlist: ["onsperson_id="+customerid]
	};

	const addressQueryparams = {
		//tablelist: [],
		joinlist: [{type:"left", table: "ONSADDRESS", condlist: ["ONSADDRESS.onsaddress_id=ONSPERSONADDRESS.onsaddress_id"]}],
		condlist: ["ONSPERSONADDRESS.onsperson_id="+customerid]
	};

	const orgQueryparams = {
		//tablelist: [],
		joinlist: [{type:"left", table: "ONSORGANIZATION", condlist: ["ONSORGANIZATION.onsorganization_id=ONSPERSONORGANIZATION.onsorganization_id"]}],
		condlist: ["ONSPERSONORGANIZATION.onsperson_id="+customerid]
	};

	const contactQueryparams = {
		tablelist: ["ONSCONTACTTYPE","ONSCONTACT"],
		joinlist: [],
		condlist: ["ONSPERSONCONTACT.onscontact_id=ONSCONTACT.onscontact_id", "ONSCONTACTTYPE.onscontacttype_id=ONSCONTACT.onscontacttype_id", "ONSPERSONCONTACT.onsperson_id="+customerid]
	};

	const seminarhistoryqueryparam = {
			//tablelist: [],
			joinlist: [
				{type:"left", table: "NNSEMINAR", condlist: ["NNSEMINAR.nnseminar_id=NNSEMINARUSER.nnseminar_id"]},
			],
			grouping: {},
			condlist: [
				"NNSEMINARUSER.onsperson_id="+customerid,
				//"NNSEMINAR.nnseminar_datetimestart>='"+defaultstartdate+"'"
			],

	};
	const franchisehistoryqueryparam = {
			//tablelist: [],
			joinlist: [
				{type:"left", table: "NNFRANCHISEPACKAGECODE", condlist: ["NNFRANCHISEPACKAGECODE.nnfranchisepackagecode_id=NNFRANCHISEUSER.nnfranchisepackagecode_id"]},
				{type:"left", table: "NNFRANCHISEUSERSTATUS", condlist: ["NNFRANCHISEUSERSTATUS.nnfranchiseuserstatus_id=NNFRANCHISEUSER.nnfranchiseuserstatus_id"]},
				{type:"left", table: "NNFRANCHISE", condlist: ["NNFRANCHISE.nnfranchise_id=NNFRANCHISEUSER.nnfranchise_id"]},
			],
			grouping: {},
			condlist: [
				"NNFRANCHISEUSER.onsperson_id="+customerid,
				//"NNFRANCHISEUSER.nnfranchiseuser_dateregistered>='"+defaultstartdate+"'"
			],
	};


	return <div className={tablescroll.headerfooter +" "+styles.editscrollholder}>
		<table>
			<tbody>
				<tr>
					<td>
						<div className={styles.sectionpercent50}>
							<WebappWidgetCard
								title={"Information"}
								token={token}
								entity={"ONSPERSON"}
								fieldList={customerFields}
								mobilerowtitlefield={[]}
								filterfieldList={[]}
								userparam={customerQueryparams}
								showRefresh={false}
								customSetData={setCustomerInfo}
							/>
							<br/>
							<WebappWidgetTable
									title={"Contact"}
									token={token}
									entity={"ONSPERSONCONTACT"}
									fieldList={contactFields}
									mobilerowtitlefield={contactMobileTitle}
									filterfieldList={[]}
									userparam={contactQueryparams}
									initsearchstate={contactInitState}
									showControls={false}
									showRefresh={false}
								/>
						</div>
						<div className={styles.sectionpercent50}>
							<WebappWidgetTable
									title={"Organizations"}
									token={token}
									entity={"onspersonorganization"}
									fieldList={orgFields}
									mobilerowtitlefield={orgMobileTitle}
									filterfieldList={[]}
									userparam={orgQueryparams}
									initsearchstate={orgInitState}
									showControls={false}
									showRefresh={false}
								/>
							<br/>
							<WebappWidgetTable
									title={"Address"}
									token={token}
									entity={"ONSPERSONADDRESS"}
									fieldList={addressFields}
									mobilerowtitlefield={addressMobileTitle}
									filterfieldList={[]}
									userparam={addressQueryparams}
									initsearchstate={addressInitState}
									showControls={false}
									showRefresh={false}
								/>
						</div>
						<div>
							<FeedbackListComponent
									token={token}
									customerid={customerid}
								/>
						</div>
						<div>
							<OrderhistoryListComponent
									title={"Order History"}
									token={token}
									customerid={customerid}
									showControls={true}
								/>
						</div>
						<div>
							<WebappWidgetTable
								title={"Franchise Application/Status"}
								token={token}
								entity={"NNFRANCHISEUSER"}
								fieldList={franchisehistoryFields}
								mobilerowtitlefield={franchisehistoryMobileTitle}
								filterfieldList={franchisehistoryFilterFields}
								userparam={franchisehistoryqueryparam}
								initsearchstate={franchisehistoryInitstate}
								showDownload={false}
								showControls={true}
								showRefresh={true}
							/>
						</div>
						<div>
							<WebappWidgetTable
								title={"Seminars Registered"}
								token={token}
								entity={"NNSEMINARUSER"}
								fieldList={seminarhistoryFields}
								mobilerowtitlefield={seminarhistoryMobileTitle}
								filterfieldList={seminarhistoryFilterFields}
								userparam={seminarhistoryqueryparam}
								initsearchstate={seminarhistoryInitstate}
								showDownload={false}
								showControls={true}
								showRefresh={true}
							/>
						</div>
					</td>
				</tr>
			</tbody>
			<tfoot className="noprint">
				<tr>
					<td className={styles.editcontrolbox+" text-centered"}>
						<button className={"textbutton "+styles.editcontrolboxtextbutton} onClick={handleClose}>
							x&nbsp;&nbsp;{customername}
						</button>
					</td>
				</tr>
			</tfoot>
		</table>
	</div>
}


export default CustomerProfileComponent;
